import { HeadingGroup } from "@sopost/react-components/dist/components/HeadingGroup";

const Home: React.FC = () => {
  return (
    <>
      <HeadingGroup title="Dashboard" />
    </>
  );
};

type ServerSideProps = {
  props: { graphQL_URL: string };
};

export async function getServerSideProps(): Promise<ServerSideProps> {
  return {
    props: {
      graphQL_URL: process.env.GRAPHQL_URL || "http://localhost:4004/graphql",
    },
  };
}

export default Home;
